<!--
 * @Author: your name
 * @Date: 2021-04-20 18:10:10
 * @LastEditTime: 2021-04-27 16:14:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user-frontend\src\views\Home.vue
-->
<template>
  <div class="cart">
    <!--  eslint-disable-next-line vue/attribute-hyphenation -->
    <header-bar :headTitle="headerTitle" :show-menu="true" />
    <div class="content">
      <div v-if="cartList.length" class="goods_list">
        <div class="tilte_box">
          <van-notice-bar
            left-icon="map-marked"
            :text="noticeText"
            v-if="noticeText"
          />
          <div v-if="feeResult" class="fee_box">
            <img src="../assets/img/fee.png" alt="" srcset="">
            <div>Free basic freight for over ${{ fee }}</div>
          </div>
        </div>
        <div v-for="(item,index) in cartList" :key="index" class="goods_item">
          <div class="left" @click="goToDetail(item.id,item.product_stock_id)">
            <img :src="item.pictures" alt="" srcset="">
          </div>
          <div class="right">
            <p class="name">
              {{ item.name }}
            </p>
            <span class="des">{{ item.des }}</span>
            <div class="all_price">
              <span class="price"><i>$</i>{{ $filters.priceHandle(item.price) }}</span>
              <div class="handle">
                <van-stepper
                  v-model="item.num"
                  disable-input
                  theme="round"
                  :min="0"
                  @plus="plusChange(item,index)"
                  @minus="minusChange(item,index)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="no_cart">
          <van-icon name="shopping-cart-o" />
          <p>No data</p>
        </div>
      </div>
      <van-submit-bar :price="allPrice" currency="$" button-text="Sumbit" @submit="onSubmit" />
    </div>
    <nav-bar />
  </div>
</template>

<script>
// @ is an alias to /src
import { reactive, onMounted, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { Toast, Dialog, Notify } from 'vant'
import navBar from '@/components/NavBar'
import HeaderBar from '@/components/HeaderBar'
import { addCart, updateCart, getCart, delCart } from '@/service/cart'
import { getFee } from '@/service/user'
import {getMoreGoodsList, getMoreGoodsListNew} from '@/service/product'
import { useStore } from 'vuex'
import { getLocal, setLocalCart, delLocalCart } from '@/common/js/utils'
import moment from 'moment'

export default {
  name: 'Home',
  components: {
    navBar,
    HeaderBar
  },
  setup() {
    const router = useRouter()
    const store = useStore()
    const state = reactive({
      headerTitle: 'My cart',
      cartList: [],
      allChecked: true,
      allPrice: 0,
      deleteStatus: false,
      checkedList: [],
      noticeText: '',
      sale_time: '',
      fee: 0, // 满减金额
      feeResult: false // 满减条件
    })
    onMounted(async() => {
      const userId = getLocal('userId')
      if (userId) {
        getCartList()
      } else {
        store.dispatch('updateLocalCart')
        var obj = store.state.cartGoods
        var idArr = []
        for (var item in obj) {
          var id = item
          var num = obj[item]
          if (num > 0) {
            idArr.push(id)
          }
        }
        if (idArr && idArr.length) {
          state.sale_time = moment(sessionStorage.getItem('localCartTime')).format('YYYY-MM-DD')
          // state.noticeText = 'Delivery date: ' + moment(state.sale_time).format('MM/DD')
          getGoodsList(idArr, obj)
        }
      }
      getFullMinus()
    })
    const onClickRight = () => {
      state.deleteStatus = !state.deleteStatus
    }
    const getCartList = async() => {
      Toast.loading({
        message: 'loading...',
        forbidClick: true
      })
      console.log('getCart===cart.vue--129')
      const res = await getCart({})
      var obj = res.data.data
      var list = obj.stock_list
      var stock_object = obj.stock_object
      var sale_time = obj.sale_time
      state.sale_time = sale_time
      // state.noticeText = 'Delivery date: ' + moment(sale_time).format('MM/DD')
      var idArr = []
      for (var item in list) {
        var id = item
        idArr.push(id)
      }
      Toast.clear()
      // getGoodsList(idArr, stock_object)
      getGoodsListNew(stock_object)
    }
   const getGoodsListNew = async(stock_object) => {
     Toast.loading({
      message: 'loading...',
      forbidClick: true
     })
     // const { data } = await getMoreGoodsList({ id_str: idStr, sale_time: state.sale_time })
     const { data } = await getMoreGoodsListNew({ stock_object })
     var list = data.data
     var allPrice = 0
     var obj = store.state.cartGoods
    console.log('obj156----->',obj)
     list.forEach(item => {
      item.num = obj[item.product_stock_id]
      item.checked = true
      allPrice += item.num * item.price
     })
     state.allPrice = allPrice
     state.cartList = list
     state.checkedList = state.cartList
     Toast.clear()
   }
    const getGoodsList = async(idArr, stock_object) => {
      if (idArr && idArr.length) {
        var idStr = idArr.join(',')
        Toast.loading({
          message: 'loading...',
          forbidClick: true
        })
        // const { data } = await getMoreGoodsList({ id_str: idStr, sale_time: state.sale_time })
        const { data } = await getMoreGoodsList({ id_str: idStr, sale_time: '' })
        var list = data.data
        var allPrice = 0
        list.forEach(item => {
          item.num = obj[item.id]
          item.checked = true
          allPrice += item.num * item.price
        })
        state.allPrice = allPrice
        state.cartList = list
        state.checkedList = state.cartList
      } else {
        state.allPrice = 0
        state.cartList = []
      }
      Toast.clear()
    }
    const plusChange = async(item, index) => {
      const goods_id = item.id
      const count = Number(item.num) + 1
      const userId = getLocal('userId')
      if (userId) {
        await addCart({
          stock_id: item.product_stock_id,
          count: count
        })
        store.dispatch('updateCart')
      } else {
        setLocalCart(goods_id, count, item.product_stock_id, item.sale_time)
        store.dispatch('updateLocalCart')
      }
      calculationTotal()
    }
    const minusChange = async(item, index) => {
      const goods_id = item.id
      const count = Number(item.num) - 1
      const userId = getLocal('userId')
      if ((item.num - 0) === 1) {
        Dialog.confirm({
          title: 'Warning',
          message: 'Are you sure you want to empty this product?'
        })
          .then(() => {
            // on confirm
            if (userId) {
              deleteAll(item.product_stock_id)
            } else {
              delLocalCart(goods_id, item.product_stock_id)
              store.dispatch('updateLocalCart')
              state.cartList.splice(index, 1)
            }
          })
          .catch(() => {
            // on cancel
            item.num = 1
          })
      } else {
        if (userId) {
          minusCount(item)
        } else {
          setLocalCart(goods_id, count, item.product_stock_id, item.sale_time)
          store.dispatch('updateLocalCart')
        }
      }
    }
    const minusCount = async(item) => {
      await updateCart({
        stock_id: item.product_stock_id,
        count: Number(item.num) - 1
      })
      store.dispatch('updateCart')
      calculationTotal()
    }
    const goToDetail = (id, stock_id) => {
      router.push({ path: '/goodsDetail', query: { id, stock_id }})
    }
    const checkedChange = (item, index) => {
      calculationTotal()
    }
    const calculationTotal = () => {
      var checkedList = []; var total = 0
      state.cartList.forEach(item => {
        if (item.checked) {
          checkedList.push(item)
          total += item.price * item.num
        }
      })
      state.allPrice = total
      state.checkedList = checkedList
    }
    const allGoodsCheck = () => {
      if (state.allChecked) {
        var price = 0
        state.cartList.forEach(item => {
          item.checked = true
          price += item.price * item.num
        })
        state.allPrice = price
        state.checkedList = state.cartList
      } else {
        state.cartList.forEach(item => {
          item.checked = false
        })
        state.allPrice = 0
        state.checkedList = []
      }
    }
    const deleteAll = async(stock_id) => {
      await delCart({
        id_list: [stock_id]
      })
      store.dispatch('updateCart')
      calculationTotal()
      getCartList()
    }
    const onSubmit = () => {
      if (state.checkedList.length === 0) {
        Toast('Please add purchase item')
      } else {
        var submitInfo = JSON.stringify(state.checkedList)
        sessionStorage.setItem('submitInfo', submitInfo)
        router.push({ path: `/submitOrder` })
      }
    }

    // 是否有满减
    const getFullMinus = async() => {
      const res = await getFee({})
      if (res.data.code) {
        Notify({ message: res.data.message, color: '#ad0000', background: '#ffe1e1' })
      } else {
        const fee = res.data.data
        if (fee !== 0) {
          state.fee = fee
          state.feeResult = true
        }
      }
    }
    return {
      ...toRefs(state),
      goToDetail,
      onSubmit,
      onClickRight,
      checkedChange,
      calculationTotal,
      allGoodsCheck,
      getCartList,
      deleteAll,
      plusChange,
      minusChange,
      minusCount,
      getFullMinus
    }
  }
}
</script>
<style lang="less">
@import '../common/style/mixin';

.cart {
  .van-nav-bar__right {
    .van-nav-bar__text {
      color: #ee0a24;
      font-weight: 700;
    }
  }
  .content {
    width: 100%;
    overflow: hidden;
    height: calc(100vh - 96px);
    margin-bottom: .4rem /* 30/75 */;
  }
  .goods_list {
    padding: .266667rem /* 20/75 */;
    box-sizing: border-box;
    width: 100%;
    height: calc(100vh - 146px);
    overflow: auto;
    .tilte_box{
      position: relative;
      .fee_box{
        position: absolute;
        top: 0;
        right: 10px;
        display: flex;
        height: 40px;
        align-items: center;
        img{
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
        div{
          font-size: 12px;
          color: #666;
          margin-top: 5px;
        }
      }
    }

    .goods_item {
      width: 100%;
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      margin-bottom: .266667rem /* 20/75 */;
      background: #fff;
      padding: .266667rem /* 20/75 */;
      align-items: center;
      &:last-child{
        margin-bottom: 0;
      }
      .goods_radio {
        margin-right: 0.1333rem;
        display: flex;
        align-items: center;
      }
    }
  }
  .van-submit-bar {
    bottom: 50px;
  }
  .no_cart {
    padding-top: 3rem;
    text-align: center;
    color: rgb(153, 153, 153);
    .van-icon {
      font-size: 1.4rem;
    }
    p {
      font-size: 0.4267rem;
    }
  }
}

</style>
