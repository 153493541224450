<!--
 * @Author: your name
 * @Date: 2021-04-20 18:10:10
 * @LastEditTime: 2021-04-27 16:14:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user-frontend\src\views\Home.vue
-->
<template>
  <div class="mine">
    <!--  eslint-disable-next-line vue/attribute-hyphenation -->
    <header-bar :headTitle="titleName" :show-menu="true" />
    <div class="content">
      <div class="user_icon">
        <div class="icon">
          <img src="./../assets/img/user.png" alt="">
        </div>
        <p class="user_name">
          {{ name }}
        </p>
      </div>
      <div class="handle_list">
        <van-cell is-link url="/addressList">
          Address book
        </van-cell>
        <van-cell is-link url="/cardBag">
          Payment methods
        </van-cell>
        <!-- <van-cell is-link url="/pointsList">
          Yaaami points
        </van-cell>
        <van-cell is-link url="/messageList">
          Message
        </van-cell>
        <van-cell is-link url="/contactUs">
          Contact us
        </van-cell> -->
        <!-- <van-cell @click="loginout">Logout</van-cell> -->
      </div>
    </div>
    <nav-bar />
  </div>
</template>

<script>
// @ is an alias to /src
// import { getLocal } from '@/common/js/utils'
import { reactive, onMounted, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { Dialog } from 'vant'
import navBar from '@/components/NavBar'
import HeaderBar from '@/components/HeaderBar'
import { setLocal, getLocal } from '@/common/js/utils'

export default {
  name: 'Home',
  components: {
    navBar,
    HeaderBar
  },
  setup() {
    const router = useRouter()
    const state = reactive({
      name: 'Yaaami',
      titleName: ''
    })
    onMounted(async() => {
      const loginInfo = getLocal('loginInfo') && JSON.parse(getLocal('loginInfo'))
      const sourceId = loginInfo && loginInfo.source_id
      if (sourceId === 1) {
        state.name = loginInfo && loginInfo.phone
      } else {
        state.name = loginInfo && loginInfo.email
      }
      var name = getLocal('userName')
      state.titleName = name || 'Yaaami'
    })

    const goToDetail = (item) => {
      router.push({ path: `/product/${item.goodsId}` })
    }

    const loginout = () => {
      Dialog.confirm({
        title: 'Warning',
        message: 'Are you sure you want to logout?'
      })
        .then(() => {
          // on confirm
          localStorage.setItem('loginInfo', '')
          setLocal('userId', '')
          router.push({ path: `/login` })
        })
        .catch(() => {
          // on cancel
        })
    }
    return {
      ...toRefs(state),
      goToDetail,
      loginout
    }
  }
}
</script>
<style lang="less">
.mine {
  .content {
    width: 100%;
    overflow: auto;
    height: calc(100vh - 96px);
    margin-bottom: .4rem /* 30/75 */;
  }
  .user_icon {
    width: 100%;
    height:20vh;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .icon {
      width: 2rem;
      height: 2rem;
      background: #e5e5e5d8;
      border-radius: 50%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      img {
        width: 45%;
      }
    }
    .user_name {
      font-size: 0.3733rem;
    }
  }
  width: 100%;
  .handle_list {
    margin: 0.2667rem 0;
  }
}
</style>
